/**
 * Created by daniel on 20.09.16.
 */

function calculateAndSetAvailableCharacters(inputNameID, maxCount, infoTextID){
    var available = maxCount-$(inputNameID).val().length;
    $(infoTextID).text(available);
}

jQuery( document ).ready( function( $ ) {
    $('#loadSettings').on('click', function (e) {
        e.preventDefault();
        var url = e.target.href;
        $.ajax({
            type: "GET",
            url: url,
            data: '',
            success: function (msg) {
                $("#favoritesModal").modal();
                console.log(msg);
            }
        });
    });

    $('#mandat_customer_id').on('change', function (e) {
        e.preventDefault();
        console.log(e.target.value);
        $.ajax({
            type: "POST",
            url: '/admin/changeCustomer',
            data: { 'customerID': e.target.value},
            success: function (msg) {
                if(msg == 'ok'){
                    location.reload();
                }

            }
        });
    });



});